import cns from "classnames";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import Container from "@Components/Container";
import Dropdown from "@Components/Dropdown";
import Image from "@Components/ui/Image";
import Link from "@Components/ui/Link";
import LinkContacts from "@Components/ui/LinkContacts";
import SvgIcon from "@Components/ui/SvgIcon";
import TextIcon from "@Components/ui/TextIcon";
import {
    useGetCommonDataBurgerBanners,
    useGetCommonDataMenuBurger,
    useGetCommonDataSocials,
} from "@Hooks/api/common";
import { useGetStoreCategories } from "@Hooks/api/store";
import useWindowSize from "@Hooks/window-size";
import { ContentMenuItem } from "@Types/api";
import { getImageSrc } from "@Utils/helpers";

import styles from "./index.module.scss";

export type BurgerMenuProps = {
    onClose?: () => void;
};

const getTabId = (id: string | number) => `tab-${id}`;

const getLinks = (links: ContentMenuItem[], level: number) => {
    const classNameLink = cns(styles.component__link, "text text_normalcase", {
        [styles.component__link_sm]: !level,
        [styles.component__link_xs]: level,
        text_h5: !level,
        text_p3: level,
    });

    return links.map(item => {
        if (item.sublinks?.length) {
            return (
                <Dropdown
                    key={item.code}
                    className={styles.component__dropdown}
                    classNameButton={classNameLink}
                    classNameButtonIcon={styles.component__linkIcon}
                    classNameListContent={styles.component__dropdownList}
                    buttonText={item.text}
                    items={getLinks(item.sublinks, level + 1)}
                />
            );
        }

        return (
            <Link
                key={item.code}
                className={classNameLink}
                href={item.link ?? "#"}
            >
                {item.text}
            </Link>
        );
    });
};

const BurgerMenu: React.FC<BurgerMenuProps> = ({ onClose }) => {
    const dataMenuBurger = useGetCommonDataMenuBurger();
    const dataSocials = useGetCommonDataSocials();
    const dataBurgerBanners = useGetCommonDataBurgerBanners();
    const storeCategories = useGetStoreCategories();

    const [tabs, setTabs] = useState<any>({});
    const [showTabImages, setShowTabImages] = useState<boolean>(true);

    const windowSize = useWindowSize();

    const socials = useMemo(
        () => dataSocials.filter(item => item.showInMenu),
        [dataSocials]
    );

    const setTabsState = useCallback((id?: string | number) => {
        const tabsObject: any = {};

        dataMenuBurger.forEach((item, itemIndex) => {
            if (item.sublinks?.length || item.categorySlug) {
                tabsObject[getTabId(itemIndex)] = id === itemIndex;
            }
        });

        setTabs(tabsObject);
    }, []);

    useEffect(() => {
        if (!windowSize.isDesktop) {
            return;
        }

        setTabsState();
    }, [windowSize.isDesktop, setTabsState]);

    const handleNavItemClick = (id: string | number) => {
        setTabsState(id);
        setShowTabImages(false);
    };

    return (
        <div className={styles.component}>
            <button
                className={styles.component__close}
                type="button"
                aria-label="Закрыть меню"
                onClick={onClose}
            >
                <SvgIcon icon="close" />
            </button>

            <Container className={styles.component__container} grid>
                <div className={styles.component__left}>
                    <div className={styles.component__nav}>
                        {dataMenuBurger.map((item, itemIndex) => {
                            if (item.sublinks?.length || item.categorySlug) {
                                return (
                                    <button
                                        key={item.code}
                                        className={cns(
                                            styles.component__navItem,
                                            "text text_h3 text_uppercase",
                                            {
                                                isActive:
                                                    tabs[getTabId(itemIndex)],
                                            }
                                        )}
                                        type="button"
                                        onClick={() =>
                                            handleNavItemClick(itemIndex)
                                        }
                                    >
                                        <TextIcon
                                            iconClassName={
                                                styles.component__navItemIcon
                                            }
                                            icon="angle-right"
                                            isRight
                                        >
                                            {item.text}
                                        </TextIcon>
                                    </button>
                                );
                            }

                            return (
                                <Link
                                    key={item.code}
                                    className={cns(
                                        styles.component__navItem,
                                        "text text_h3 text_uppercase"
                                    )}
                                    href={item.link ?? "#"}
                                >
                                    {item.text}
                                </Link>
                            );
                        })}
                    </div>

                    {!!socials.length && (
                        <div
                            className={cns(
                                styles.component__socials,
                                "text text_h5"
                            )}
                        >
                            {socials.map((social, i) => (
                                <LinkContacts key={i} contact={social} />
                            ))}
                        </div>
                    )}
                </div>

                <div className={styles.component__right}>
                    {showTabImages && !!dataBurgerBanners.length && (
                        <div
                            className={cns(
                                styles.component__tab,
                                styles.component__tab_images
                            )}
                        >
                            <div className={styles.component__images}>
                                {dataBurgerBanners.map((item, itemIndex) => (
                                    <div
                                        key={itemIndex}
                                        className={styles.component__image}
                                    >
                                        <Image
                                            src={getImageSrc(item.media[0].src)}
                                            alt=""
                                            fill
                                            size="30vw"
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    {dataMenuBurger.map((item, itemIndex) => {
                        const itemSublinks = useMemo(
                            () =>
                                item.categorySlug
                                    ? storeCategories.getMenuItems(
                                          item.categorySlug,
                                          false,
                                          true,
                                          0,
                                          0,
                                          [1]
                                      )
                                    : item.sublinks,
                            [dataMenuBurger]
                        );

                        if (!itemSublinks || !itemSublinks.length) {
                            return null;
                        }

                        const maxLinksInCol = Math.ceil(
                            itemSublinks.length / 2
                        );

                        const linksInCol1 = itemSublinks.filter(
                            (link: ContentMenuItem, linkIndex: number) =>
                                linkIndex < maxLinksInCol
                        );

                        const linksInCol2 = itemSublinks.filter(
                            (link: ContentMenuItem, linkIndex: number) =>
                                linkIndex >= maxLinksInCol
                        );

                        const linkElement = (
                            mainLink: ContentMenuItem,
                            mainLinkIndex: number
                        ) => (
                            <React.Fragment key={mainLinkIndex}>
                                <Link
                                    key={mainLinkIndex}
                                    className={styles.component__link}
                                    href={mainLink.link ?? "#"}
                                >
                                    {mainLink.text}
                                </Link>

                                {!!mainLink.sublinks?.length &&
                                    getLinks(mainLink.sublinks, 0)}
                            </React.Fragment>
                        );

                        return (
                            <div
                                key={item.code}
                                className={cns(
                                    styles.component__tab,
                                    "text text_h4 text_uppercase",
                                    {
                                        isHidden: !tabs[getTabId(itemIndex)],
                                    }
                                )}
                            >
                                <div className={styles.component__col}>
                                    {linksInCol1.map(linkElement)}
                                </div>

                                {!!linksInCol2.length && (
                                    <div className={styles.component__col}>
                                        {linksInCol2.map(linkElement)}
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>
            </Container>
        </div>
    );
};

export default BurgerMenu;
